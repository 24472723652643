import { Component } from '@angular/core';
import { Router } from '@angular/router';
import { SideNavDirection } from './side-nav/side-nav.component';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html'
})
export class AppComponent {
  SideNavDirection = SideNavDirection;
  constructor(private router: Router) {}


  getNavLinkIsActive(param?: string): boolean {
    if(!!param) {
      return this.router.url.includes(param);
    } else {
      return false;
    }
  }

}
