import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { RouterModule } from '@angular/router';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { HomeComponent } from './home/home.component';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { HeaderComponent } from './header/header.component';
import { HttpClientModule } from '@angular/common/http';
import { FooterComponent } from './footer/footer.component';
import { TermsComponent } from './terms/terms.component';
import { BrandsComponent } from './brands/brands.component';
import { SideNavComponent } from './side-nav/side-nav.component';
import { TestimonialsComponent } from './testimonials/testimonials.component';
import { NewsComponent } from './news/news.component';
import { LaunchLandingPageComponent } from './launch-landing-page/launch-landing-page.component';
import { MarkingguidelinesComponent } from './markingguidelines/markingguidelines.component';

@NgModule({
  declarations: [
    AppComponent,
    HomeComponent,
    HeaderComponent,
    FooterComponent,
    TermsComponent,
    BrandsComponent,
    SideNavComponent,
    TestimonialsComponent,
    NewsComponent,
    LaunchLandingPageComponent,
    MarkingguidelinesComponent,
  ],
  imports: [
    BrowserModule,
    RouterModule,
    HttpClientModule,
    AppRoutingModule,
    NgbModule
  ],
  providers: [],
  bootstrap: [AppComponent]
})
export class AppModule { }
