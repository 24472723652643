
<div class="container news-container">
  <ng-container *ngIf="!article">
    <div class="superfly-header">
      <h1>News</h1>
    </div>
    <div class="row mx-2">
      <div class="col-lg-8">
        <div class="news-section" (click)="readNews(0)">
          <img class="mb-4" [src]="getImageSrc(0)" alt="Main News">
          <div class="first-section">
            <span>{{getDate(0)}}</span>
            <h3 class="pb-2 smallNews">{{getTitle(0)}}</h3>
            <p class="d-none d-sm-block mainNews">{{getContentMainNews()}}</p>
          </div>
        </div>
      </div>
      <div class="col-lg-4">
        <div class="d-flex flex-column">
          <div class="news-section" (click)="readNews(1)">
            <img class="mb-4" [src]="getImageSrc(1)" alt="Second News">
            <div>
              <span>{{getDate(1)}}</span>
              <h3 class="smallNews">{{getTitle(1)}}</h3>
            </div>
          </div>
          <div class="news-section" (click)="readNews(2)">
            <img class="mb-4" [src]="getImageSrc(2)" alt="Third News">
            <div>
              <span>{{getDate(2)}}</span>
              <h3 class="smallNews">{{getTitle(2)}}</h3>
            </div>
          </div>
        </div>
      </div>
    </div>
  </ng-container>
  <ng-container *ngIf="article">
    <h2 class="pb-2">{{articleTitle}}</h2>
    <div class="article" [outerHTML]="article"></div>
  </ng-container>
</div>

