import { HomeComponent } from './home/home.component';
import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { PagesResolver } from './services/pages.resolver';
import { TermsComponent } from './terms/terms.component';
import { BrandsComponent } from './brands/brands.component';
import { NewsComponent } from './news/news.component';
import { LaunchLandingPageComponent } from './launch-landing-page/launch-landing-page.component';
import { MarkingguidelinesComponent } from './markingguidelines/markingguidelines.component';

const routes: Routes = [
  {
    path: '', component: HomeComponent, resolve: { pages: PagesResolver }
  },
  {
    path: 'terms', component: TermsComponent, resolve: { pages: PagesResolver }
  },
  {
    path: 'brands', component: BrandsComponent, resolve: { pages: PagesResolver }
  },
  {
    path: 'news', component: NewsComponent, resolve: { pages: PagesResolver }
  },
  {
    path: 'superfly-launch', component: LaunchLandingPageComponent, resolve: { pages: PagesResolver }
  },
  {
    path: 'markingguidelines', component: MarkingguidelinesComponent, resolve: { pages: PagesResolver }
  },
  {
    path: '**' , redirectTo : ''
  }
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
