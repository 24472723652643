import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, Observer } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { Page } from '../models/pages';
import { SectionSlugs } from './core.models';

@Injectable({
  providedIn: 'root'
})
export class CoreService {
  private blogUrl = 'https://fullcreamaffiliates.com/';

  constructor(private http: HttpClient) { }

  getPages(): Observable<Page | any> {
    const url = `${this.blogUrl}/wp-json/wp/v2/posts?per_page=100`;
    return this.http.get(url).pipe(catchError(this.errorHandler));
  }

  getSlugData(pages: Page[]) {
    console.log(Object.values(SectionSlugs));
    let slugPages = {};
    pages.forEach((page: Page) => {
      Object.values(SectionSlugs).forEach((slug: SectionSlugs) => {
        if(page.slug === slug) {
          slugPages = {...slugPages, [page.slug]: page}
        }
      });
    })
    return slugPages;
  }

  errorHandler(error: HttpErrorResponse) {
    return new Observable((observer: Observer<any>) => {
      observer.error(error);
    });
  }

  isMobileOrTablet(): boolean {
    return window.innerWidth < 1200;
  }
}
