<div class="container my-5 superfly-terms">
  <div class="superfly-header">
    <h1 [innerHtml]="pageTitleSection(sectionSlugs.TERMS)"></h1>
  </div>
  <div [innerHtml]="pageContentSection(sectionSlugs.TERMS)"></div>
  <div class="pt-3">
    <a href="/assets/marketing-guidelines.pdf" target="_blank" title="Read PDF"
    >Superfly Partners - Marketing Guidelines</a
  >
  </div>
</div>
