<div class="container brands-container">
  <div class="superfly-header-all">
    <h1>Our brands</h1>
  </div>
  <div class="my-5">
    <div class="superfly-header-brand">
      <h1 [innerHtml]="pageTitleSection(sectionSlugs.BRANDHELLO)"></h1>
    </div>
    <div class="superfly-brand">
      <a href="https://www.hellocasino.com">
        <img src="assets/hello-logo.png" alt="Hello Casino Logo" />
      </a>
      <div [innerHtml]="pageContentSection(sectionSlugs.BRANDHELLO)"></div>
    </div>
  </div>
  <div class="my-5">
    <div class="superfly-header-brand">
      <h1 [innerHtml]="pageTitleSection(sectionSlugs.BRANDSLOTNITE)"></h1>
    </div>
    <div class="superfly-brand">
      <a href="https://www.slotnite.com">
        <img src="assets/slotnite-logo.png" alt="Slotnite Casino Logo" />
      </a>
      <div [innerHtml]="pageContentSection(sectionSlugs.BRANDSLOTNITE)"></div>
    </div>
  </div>
  <div class="my-5">
    <div class="superfly-header-brand">
      <h1 [innerHtml]="pageTitleSection(sectionSlugs.BRANDSKOLCASINO)"></h1>
    </div>
    <div class="superfly-brand">
      <a href="https://www.skolcasino.com" [ngClass]="{'mobile-align': isMobile()}">
        <img src="assets/skol-logo.png" alt="Skol Casino Logo" />
      </a>
      <div [innerHtml]="pageContentSection(sectionSlugs.BRANDSKOLCASINO)"></div>
    </div>
  </div>
  <div class="my-5">
    <div class="superfly-header-brand">
      <h1 [innerHtml]="pageTitleSection(sectionSlugs.BRANDGDAY)"></h1>
    </div>
    <div class="superfly-brand">
      <a href="https://www.gdaycasino.com" [ngClass]="{'mobile-align': isMobile()}">
        <img src="assets/gday-logo.png" alt="Gday Casino Logo" />
      </a>
      <div [innerHtml]="pageContentSection(sectionSlugs.BRANDGDAY)"></div>
    </div>
  </div>
  <div class="my-5">
    <div class="superfly-header-brand">
      <h1 [innerHtml]="pageTitleSection(sectionSlugs.BRANDDIAMOND7)"></h1>
    </div>
    <div class="superfly-brand">
      <a href="https://www.gdaycasino.com">
        <img src="assets/diamond7-logo.png" alt="Gday Casino Logo" />
      </a>
      <div [innerHtml]="pageContentSection(sectionSlugs.BRANDDIAMOND7)"></div>
    </div>
  </div>
  <div class="my-5">
    <div class="superfly-header-brand">
      <h1 [innerHtml]="pageTitleSection(sectionSlugs.BRAND21PRIVE)"></h1>
    </div>
    <div class="superfly-brand">
      <a href="https://www.gdaycasino.com">
        <img src="assets/21prive-logo.png" alt="Gday Casino Logo" />
      </a>
      <div [innerHtml]="pageContentSection(sectionSlugs.BRAND21PRIVE)"></div>
    </div>
  </div>
</div>
