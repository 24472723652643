import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { CoreService } from './../services/core.service';
import { SectionSlugs } from '../services/core.models';

@Component({
  selector: 'app-launch',
  templateUrl: './launch-landing-page.component.html',
  styleUrls: ['./launch-landing-page.component.scss'],
})
export class LaunchLandingPageComponent implements OnInit {
  pages: any;

  constructor(
    private coreService: CoreService,
    private route: ActivatedRoute
  ) {}

  ngOnInit(): void {
    this.pages = this.coreService.getSlugData(
      this.route.snapshot.data['pages']
    );
  }

  pageTitleSection(): string {
    return this.pages[SectionSlugs.SITELAUNCH]?.title?.rendered;
  }

  pageContentSection(): string {
    return this.pages[SectionSlugs.SITELAUNCH]?.content?.rendered;
  }

}
