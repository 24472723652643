import { CoreService } from './../services/core.service';
import { AfterViewInit, Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { SectionSlugs } from '../services/core.models';

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.scss'],
})
export class HomeComponent implements OnInit, AfterViewInit {

  readonly sectionSlugs = SectionSlugs;

  homePost: string = '';
  errorMessage: any;
  pages: any;

  constructor(
    private coreService: CoreService,
    private route: ActivatedRoute,
    private router: Router
  ) {}

  ngOnInit(): void {
    this.pages = this.coreService.getSlugData(
      this.route.snapshot.data['pages']
    );
  }

  pageTitleSection(section: string): string {
    return this.pages[section]?.title?.rendered;
  }

  pageContentSection(section: string): string {
    return this.pages[section]?.content?.rendered;
  }

  scrollIntoSection(sectionId: string): void {
    const section = document.getElementById(sectionId) as HTMLElement;
    section.scrollIntoView();
    setTimeout(()=> {
      window.scrollTo(0, window.scrollY - 100);
    },1200)
  }

  ngAfterViewInit(): void {
    const link = this.router.url;
    if(link.includes('/#')) {
      this.scrollIntoSection(link.slice(2));
    }
  }

  isMobile(): boolean {
    return this.coreService.isMobileOrTablet();
  }

}
