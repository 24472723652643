<app-side-nav
  [sidenavTemplateRef]="navContent"
  [direction]="SideNavDirection.Left"
  [navWidth]="280"
  [duration]="0.5"></app-side-nav>

<superfly-header></superfly-header>
<router-outlet></router-outlet>
<superfly-footer></superfly-footer>

<ng-template #navContent>
  <ul class="navbar-nav mr-auto nav-pills">
    <li class="nav-item">
      <a class="nav-link" [ngClass]="{'active': getNavLinkIsActive('home')}" href="#home">Home</a>
    </li>
    <li class="nav-item">
      <a class="nav-link" [ngClass]="{'active': getNavLinkIsActive('brands')}" href="/brands">Our brands</a>
    </li>
    <li class="nav-item">
      <a class="nav-link"  [ngClass]="{'active': getNavLinkIsActive('news')}" href="/news">News</a>
    </li>
    <li class="nav-item">
      <a class="nav-link" [ngClass]="{'active': getNavLinkIsActive('faq')}" href="#faq">FAQ</a>
    </li>
    <li class="nav-item">
      <a class="nav-link" [ngClass]="{'active': getNavLinkIsActive('testimonial')}" href="#testimonial">Testimonial</a>
    </li>
    <li class="nav-item">
      <a class="nav-link" [ngClass]="{'active': getNavLinkIsActive('terms')}" href="/terms">T&C's</a>
    </li>
  </ul>
</ng-template>
