import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { SectionSlugs } from '../services/core.models';
import { CoreService } from '../services/core.service';

@Component({
  selector: 'app-terms',
  templateUrl: './terms.component.html',
  styleUrls: ['./terms.component.scss'],
})
export class TermsComponent implements OnInit {

  readonly sectionSlugs = SectionSlugs;
  pages: any;

  constructor(
    private coreService: CoreService,
    private route: ActivatedRoute
  ) {}

  ngOnInit(): void {
    this.pages = this.coreService.getSlugData(
      this.route.snapshot.data['pages']
    );
  }

  pageTitleSection(section: string): string {
    return this.pages[section]?.title?.rendered;
  }

  pageContentSection(section: string): string {
    return this.pages[section]?.content?.rendered;
  }
}
