<div class="footer-container">
  <div class="footer-container-pre">
    <div>
      <a href="https://www.whitehatgaming.com" target="_blank">
        <img src="assets/logos/whitehat.png" alt="Whitehat logo" />
      </a>
      <a href="https://www.gpwa.org/" target="_blank">
        <img src="assets/logos/gpwa.png" alt="GPWA logo" />
      </a>
      <a href="https://statsdrone.com/" target="_blank">
        <img src="assets/logos/statsdrone.png" alt="Stats Drone logo" />
      </a>
      <a href="https://gaffg.com/" target="_blank">
        <img src="assets/logos/gaffg.png" alt="Gaffg logo" />
      </a>
    </div>
  </div>
  <div class="footer-container-main">
    <div class="container">
      <div class="row justify-content-start">
        <div class="col-lg-5 footer-container-main-contact">
          <h6>Contact</h6>
          <p>support@superflypartners.net</p>
          <p>
            Cornerstone Building Level 3 Suite 4, 16th Sept Square, Mosta MST
            1180, Malta
          </p>
          <div class="d-flex mb-4">
            <a href="https://join.skype.com/invite/n6xNEQL2ZyNZ" target="_blank">
              <img src="assets/logos/skype-logo.png" alt="Skype Logo" />
            </a>
            <a href="https://www.linkedin.com/company/76718691/admin/" target="_blank">
              <img src="assets/logos/linkedin-logo.png" alt="Linkedin Logo" />
            </a>
          </div>
          <a class="text-white" href="/markingguidelines">Marketing Information</a>
        </div>
        <div class="col-lg-5">
          <div class="pt-5 d-flex">
            <a href="#">
              <img src="assets/logos/18plus.png" alt="18 Plus" />
            </a>
            <a href="https://www.begambleaware.org/" target="_blank">
              <img src="assets/logos/BeGambleAware.png" alt="BeGambleAware" />
            </a>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
