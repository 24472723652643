import { ChangeDetectorRef, Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { CoreService } from '../services/core.service';

@Component({
  selector: 'app-testimonials',
  templateUrl: './testimonials.component.html',
  styleUrls: ['./testimonials.component.scss']
})
export class TestimonialsComponent implements OnInit {
  content: string[] = [];
  pages: any;

  constructor(
    private route: ActivatedRoute,
    private cdr: ChangeDetectorRef,
    ) {}


  ngOnInit(): void {
    this.route.snapshot.data['pages'].forEach((page: any) => {
      if (page?.slug?.includes('testimonial-')) {
        this.content.push(page?.content?.rendered);
      }
    });

    this.cdr.detectChanges();
  }


}
