export enum SectionSlugs {
  HOMEBANNERBOX = 'can-ya-dig-it',
  HOMEBANNERWELCOME = 'home-banner-welcome',
  REVIEWSHARE = 'review-share',
  HOMECOMMISSIONCPA = 'home-commission-cpa',
  HOMECOMMISSIONHYBRID = 'home-commission-hybrid',
  TERMS = 'superfly-terms',
  BRANDHELLO = 'brands-hello',
  BRANDSLOTNITE = 'brands-slotnite',
  BRANDSKOLCASINO = 'brands-skol',
  BRANDGDAY = 'brands-gday',
  BRANDDIAMOND7 = 'brands-diamond7',
  BRAND21PRIVE = 'brands-21prive',
  MARKINGGUIDELINES = 'marketingguidelines',
  FAQ1 = 'home-faq-1',
  FAQ2 = 'home-faq-2',
  FAQ3 = 'home-faq-3',
  FAQ4 = 'home-faq-4',
  FAQ5 = 'home-faq-5',
  FAQ6 = 'home-faq-6',
  SITELAUNCH = 'superfly-site-launch',
}
