<div class="superfly-container">
  <div class="superfly-bg">
    <div class="superfly-bg-box">
      <div class="d-flex">
        <div class="d-flex flex-column">
          <h1 [innerHTML]="pageTitleSection(sectionSlugs.HOMEBANNERBOX)"></h1>
          <p [innerHTML]="pageContentSection(sectionSlugs.HOMEBANNERBOX)">
          </p>
        </div>
        <img class="img-fluid" src="assets/superfly-vegas-elvis.png" alt="Superfly Vegas Elvis">
      </div>
      <a class="btn" href="https://afs.fullcreamaffiliates.com/afs/wsignup.form.php">Register</a
      ><!--TODO-A To put the correct link here-->
    </div>
  </div>
  <!--Our Brands-->
  <div class="container">
    <div class="superfly-welcome">
      <img src="assets/superfly-fullcream-logo.jpg" alt="Superfly Vegas Elvis" />
      <div class="superfly-welcome-box">
        <h1 [innerHtml]="pageTitleSection(sectionSlugs.HOMEBANNERWELCOME)"></h1>
        <div [innerHtml]="pageContentSection(sectionSlugs.HOMEBANNERWELCOME)"></div>
      </div>
    </div>
    <div class="superfly-header">
      <h1>Our Brands</h1>
    </div>
    <a id="superflybrands"></a>
    <div class="superfly-brands">
      <a href="https://www.hellocasino.com">
        <img src="assets/hello-logo.png" alt="Hello Casino Logo" />
      </a>
      <a href="https://www.slotnite.com">
        <img src="assets/slotnite-logo.png" alt="Slotnite Casino Logo" />
      </a>
      <a href="https://www.skolcasino.com">
        <img src="assets/skol-logo.png" alt="Skol Casino Logo" />
      </a>
      <a href="https://www.diamond7casino.com">
        <img src="assets/diamond7-logo.png" alt="Diamond7 Casino Logo" />
      </a>
      <a href="https://www.gdaycasino.com">
        <img src="assets/gday-logo.png" alt="Gday Casino Logo" />
      </a>
      <a href="https://www.21prive.com">
        <img src="assets/21prive-logo.png" alt="21Prive Casino Logo" />
      </a>
    </div>
    <div class="superfly-licence">
      <p>All our brands are licensed in the UK and Malta.</p>
      <div>
        <a href="https://www.mga.org.mt/">
          <img src="assets/mga.png" alt="Malta Gaming Authority" />
        </a>
        <a href="https://www.gamblingcommission.gov.uk/">
          <img src="assets/gambling-commision.png" alt="Gambling commision" />
        </a>
      </div>
    </div>
  </div>
  <!--Why Superfly Partners-->
  <div class="superfly-groovy">
    <div class="superfly-groovy-box">
      <div class="superfly-header">
        <h1 id="sfp">Why Superfly Partners</h1>
      </div>
      <ul>
        <li>
          <span class="superfly-groovy-box-circle" [ngClass]="{'first-circle-mobile': isMobile()}"><span></span></span>
          Longevity – been operating for 13 years and set up by industry experts
        </li>
        <li>
          <span class="superfly-groovy-box-circle" [ngClass]="{'second-circle-mobile': isMobile()}"><span></span></span> Multiple
          innovative brands with enticing welcome offers, localised payment
          methods and an extensive game library (over 2500 games from over 100
          providers)
        </li>
        <li>
          <span class="superfly-groovy-box-circle" [ngClass]="{'third-circle-mobile': isMobile()}"><span></span></span> Prompt
          monthly payment, one of the first credits to be in your account each
          month
        </li>
        <li>
          <span class="superfly-groovy-box-circle" [ngClass]="{'fourth-circle-mobile': isMobile()}"><span></span></span>
          Dedicated Affiliate Managers
        </li>
        <li>
          <span class="superfly-groovy-box-circle" [ngClass]="{'fourth-circle-mobile': isMobile()}"><span></span></span> Operate
          on the renowned White Hat Gaming platform
        </li>
      </ul>
    </div>
  </div>
  <!--Commission-->
  <div class="mb-3 superfly-header my-5">
    <h1 id="commission">Commission</h1>
  </div>
  <div class="container">
    <div class="superfly-commission">
      <div class="superfly-commission-container">
        <div class="superfly-commission-container-box">
          <img src="assets/revenue-share.png" alt="Revenue Share" />
          <h1 [innerHtml]="pageTitleSection(sectionSlugs.REVIEWSHARE)"></h1>
          <div [innerHtml]="pageContentSection(sectionSlugs.REVIEWSHARE)"></div>
        </div>
        <div class="superfly-commission-container-box">
          <img src="assets/cpa.png" alt="CPA" />
          <h1 [innerHtml]="pageTitleSection(sectionSlugs.HOMECOMMISSIONCPA)"></h1>
          <div [innerHtml]="pageContentSection(sectionSlugs.HOMECOMMISSIONCPA)"></div>
        </div>
        <div class="superfly-commission-container-box">
          <img src="assets/hybrid.png" alt="Hybrid" />
          <h1 [innerHtml]="pageTitleSection(sectionSlugs.HOMECOMMISSIONHYBRID)"></h1>
          <div [innerHtml]="pageContentSection(sectionSlugs.HOMECOMMISSIONHYBRID)"></div>
        </div>
      </div>
    </div>
  </div>
  <!--News-->
  <div class="container d-flex flex-column">
    <app-news></app-news>
    <a class="align-self-end see-all-news" href="/news">See all news</a>
  </div>
  <!--FAQ-->
  <div class="container">
    <div class="superfly-header my-5">
      <h1 id="faq">FAQ</h1>
    </div>
    <ngb-accordion #acc="ngbAccordion">
      <ngb-panel id="toggle-1" title="{{pageTitleSection(sectionSlugs.FAQ1)}}">
        <ng-template ngbPanelContent>
          <div [innerHtml]="pageContentSection(sectionSlugs.FAQ1)"></div>
        </ng-template>
      </ngb-panel>
      <ngb-panel id="toggle-2" title="{{pageTitleSection(sectionSlugs.FAQ2)}}">
        <ng-template ngbPanelContent>
          <div [innerHtml]="pageContentSection(sectionSlugs.FAQ2)"></div>
        </ng-template>
      </ngb-panel>
      <ngb-panel id="toggle-3" title="{{pageTitleSection(sectionSlugs.FAQ3)}}">
        <ng-template ngbPanelContent>
          <div [innerHtml]="pageContentSection(sectionSlugs.FAQ3)"></div>
        </ng-template>
      </ngb-panel>
      <ngb-panel id="toggle-4" title="{{pageTitleSection(sectionSlugs.FAQ4)}}">
        <ng-template ngbPanelContent>
          <div [innerHtml]="pageContentSection(sectionSlugs.FAQ4)"></div>
        </ng-template>
      </ngb-panel>
      <ngb-panel id="toggle-5" title="{{pageTitleSection(sectionSlugs.FAQ5)}}">
        <ng-template ngbPanelContent>
          <div [innerHtml]="pageContentSection(sectionSlugs.FAQ5)"></div>
        </ng-template>
      </ngb-panel>
      <ngb-panel id="toggle-6" title="{{pageTitleSection(sectionSlugs.FAQ6)}}">
        <ng-template ngbPanelContent>
          <div [innerHtml]="pageContentSection(sectionSlugs.FAQ6)"></div>
        </ng-template>
      </ngb-panel>
    </ngb-accordion>
  </div>
  <div class="container">
    <div class="superfly-header my-5">
      <h1 id="testimonial">Testimonials</h1>
    </div>
    <app-testimonials></app-testimonials>
  </div>
</div>
