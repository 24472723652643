import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { SectionSlugs } from '../services/core.models';
import { CoreService } from '../services/core.service';

@Component({
  selector: 'app-brands',
  templateUrl: './brands.component.html',
  styleUrls: ['./brands.component.scss'],
})
export class BrandsComponent implements OnInit {

  readonly sectionSlugs = SectionSlugs;
  pages: any;

  constructor(
    private coreService: CoreService,
    private route: ActivatedRoute
  ) {}

  ngOnInit(): void {
    this.pages = this.coreService.getSlugData(
      this.route.snapshot.data['pages']
    );
  }

  pageTitleSection(section: string): string {
    return this.pages[section]?.title?.rendered;
  }

  pageContentSection(section: string): string {
    return this.pages[section]?.content?.rendered;
  }

  isMobile(): boolean {
    return this.coreService.isMobileOrTablet();
  }
}
