<nav *ngIf="!isMobileOrTablet()" class="navbar navbar-expand-lg navbar-light nav-container shadow-sm fixed-top" id="mainNav" #headerNav>
  <div class="container px-5">
    <a class="navbar-brand fw-bold" href="/">
      <img src="/assets/superfly-logo.png" alt="Superfly partners" />
    </a>
    <div class="collapse navbar-collapse navbar-responsive" id="navbarResponsive">
      <ul class="navbar-nav me-4 my-4 my-lg-0">
        <li class="nav-item">
          <a class="nav-link" [ngClass]="{'active': getNavLinkIsActive('brands')}" href="/brands">Our Brands</a>
        </li>
        <li class="nav-item">
          <a class="nav-link" [ngClass]="{'active': getNavLinkIsActive('sfp')}" href="#" (click)="scrollIntoSection('sfp')">Why SFP</a>
        </li>
        <li class="nav-item">
          <a class="nav-link" [ngClass]="{'active': getNavLinkIsActive('news')}" href="/news">News</a>
        </li>
        <li class="nav-item">
          <a class="nav-link" [ngClass]="{'active': getNavLinkIsActive('commission')}" href="#commission" (click)="scrollIntoSection('commission')">Commission</a>
        </li>
        <li class="nav-item">
          <a class="nav-link" [ngClass]="{'active': getNavLinkIsActive('faq')}" href="#faq" (click)="scrollIntoSection('faq')">FAQ</a>
        </li>
        <li class="nav-item">
          <a class="nav-link" [ngClass]="{'active': getNavLinkIsActive('testimonial')}" href="#testimonial" (click)="scrollIntoSection('testimonial')">Testimonial</a>
        </li>
        <li class="nav-item">
          <a class="nav-link" [ngClass]="{'active': getNavLinkIsActive('terms')}" href="/terms">T&Cs</a>
        </li>
      </ul>
    </div>

    <div class="d-flex justify-content-end mobile-menu-container">
      <div>
        <a class="mobile-login" href="https://afs.superflypartners.net/afs/login.php?autologin=1">Login</a>
        <a href="https://afs.superflypartners.net/afs/wsignup.form.php" class="mobile-register">Register</a>
      </div>
    </div>
  </div>
</nav>

<nav *ngIf="isMobileOrTablet()"
  class="nav-mobile nav-container shadow-sm navbar navbar-light position-sticky fixed-top">
  <div class="d-flex justify-content-between mobile-menu-container">
    <div>
      <img class="burger-menu" src="../../assets/icons/burger-menu.svg" (click)="toggleSideNav()" />
      <a class="navbar-brand fw-bold" href="/">
        <img src="/assets/superfly-logo.png" alt="Superfly partners" class="mobile-logo" />
      </a>
    </div>
    <div>
      <a class="mobile-login" href="https://afs.superflypartners.net/afs/login.php?autologin=1">Login</a>
      <a href="https://afs.superflypartners.net/afs/wsignup.form.php" class="mobile-register">Register</a>
    </div>
  </div>
</nav>
