import { Component, ElementRef, HostListener, OnInit, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { CoreService } from '../services/core.service';
import { NavigationService } from '../services/navigation.service';

@Component({
  selector: 'superfly-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss']
})
export class HeaderComponent implements OnInit {
  @ViewChild('headerNav') heaverNav: ElementRef;
  navbarCollapsed = true;

  constructor(
    private coreService: CoreService,
    private navigationService: NavigationService,
    private router: Router,
  ) { }

  ngOnInit(): void {
  }

  @HostListener('window:scroll', ['$event'])
  onScroll(event: any): void {
    this.heaverNav.nativeElement.style.backgroundColor = '#ffffff';
    if(document.documentElement.scrollTop === 0) {
      this.heaverNav.nativeElement.style.backgroundColor = 'rgba(255, 255, 255, 0.8)';
    }
  }

  isMobileOrTablet(): boolean {
    return this.coreService.isMobileOrTablet();
  }

  toggleSideNav() {
    this.navigationService.setShowNav(true);
  }

  scrollIntoSection(sectionId: string): void {
    const section = document.getElementById(sectionId) as HTMLElement;
    if(!section) {
      window.location.href = window.location.origin + '/#' + sectionId;
    }
    section.scrollIntoView();
    setTimeout(()=> {
      window.scrollTo(0, window.scrollY - 100);
    },1000)
  }

  getNavLinkIsActive(param?: string): boolean {
    if(!!param) {
      return this.router.url.includes(param);
    } else {
      return false;
    }
  }

}
