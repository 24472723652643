import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';

@Component({
  selector: 'app-news',
  templateUrl: './news.component.html',
  styleUrls: ['./news.component.scss'],
})
export class NewsComponent implements OnInit {

  article: string = '';
  articleTitle: string = '';

  private news: any[] = [];

  constructor(private route: ActivatedRoute, private router: Router) {}

  ngOnInit(): void {
    this.route.snapshot.data['pages'].forEach((news: any) => {
      if (news?.slug?.includes('news')) {
        this.news.push(news);
      }
    });
    this.route.queryParams
    .subscribe(params => {
      if(params['url']) {
        const slugIndex = this.news.map(article => article.slug).indexOf(params['url']);
        if(slugIndex > -1) {
          this.article = this.setImageWidthHeight(this.getContent(slugIndex));
          this.articleTitle = this.getTitle(slugIndex);
        }
      }
    });
  }

  getImageSrc(newsOrder: number): string {
    const content = this.getContent(newsOrder);
    const tmp: any = document.createElement('div');
    tmp.innerHTML = content;
    const src = tmp.querySelector('img').getAttribute('src');
    return src;
  }

  getTitle(newsOrder: number): string {
    return this.news[newsOrder]?.title?.rendered;
  }

  getContent(newsOrder: number): string {
    return this.news[newsOrder]?.content?.rendered;
  }

  getDate(newsOrder: number): string {
    return new Date(this.news[newsOrder]?.date).toDateString();
  }

  getContentMainNews(): string {
    const content = this.getContent(0);
    const tmp: any = document.createElement('div');
    tmp.innerHTML = content;
    const text = tmp.querySelector('p').innerText;
    return text;
  }

  readNews(news: number): void {
    const urlSlug = this.news[news]?.slug;
    if(window.location.href.indexOf('news') > -1){
      window.location.href = window.location.href + `?url=${urlSlug}`;
    } else {
      window.location.href = window.location.href + `news?url=${urlSlug}`;
    }
  }

  private setImageWidthHeight(content: string): string {
    const tmp: any = document.createElement('div');
    tmp.className = 'article';
    tmp.innerHTML = content;
    tmp.querySelector('img').removeAttribute('width');
    tmp.querySelector('img').removeAttribute('height');
    return tmp.outerHTML;
  }
}
